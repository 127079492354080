<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img :src="homeData ? homeData.login_page_logo :'@/assets/images/pages/login.svg'"
                   :alt="getOrgName()"
                   style="margin: auto; max-height: 200px;"
                   class="">
              <div class="my-6 mx-6" v-if="homeData">
                  <div slot="no-body" class=" p-6">
                    <h2>{{ $t('Contact') }}</h2>
                    <div class="py-4 ">

                      <div class="flex items-end leading-none py-1">
                        <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="mr-4" />
                        <a :href="'tel:' + homeData.contact_details.phone"><span class="mt-1">{{ homeData.contact_details.phone }}</span></a>
                      </div>
                      <div class="flex items-end leading-none pt-4 pb-1" v-if="homeData.contact_details.whatsapp">
                        <font-awesome-icon :icon="['fab', 'whatsapp']" size="lg" class="mr-4"/>
                        <!--              <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="mr-4" />-->
                        <a :href="'https://wa.me/' + homeData.contact_details.whatsapp.replace(' ', '')"
                           target="_blank">
                          <span class="mt-1">{{ homeData.contact_details.whatsapp }}</span>
                        </a>
                      </div>
                      <div class="flex items-end leading-none pt-4 pb-1" v-if="homeData.contact_details.fax">
<!--                        <font-awesome-icon :icon="['fab', 'whatsapp']" size="lg" class="mr-4"/>-->
                        <feather-icon icon="PrinterIcon" svgClasses="h-5 w-5" class="mr-4" />
                        <!--              <feather-icon icon="PhoneCallIcon" svgClasses="h-5 w-5" class="mr-4" />-->
                        <a
                           target="_blank">
                          <span class="mt-1">{{ homeData.contact_details.fax }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="flex justify-center">
                <router-link to="/">
                  <vs-button icon-pack="feather" icon="icon-chevrons-left">{{ $t('BackToHome') }}</vs-button>
                </router-link>
              </div>
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="px-8 pt-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">{{homeData != null ? homeData.organisation_name : ''}} {{$t('Login')}} </h4>
                  <p>{{$t('WelcomeBackLoginText')}}</p>
                </div>

                <div class="py-3"></div>

                <login-jwt></login-jwt>

              </div>
            </div>


          </div>

        </div>
      </vx-card>

    </div>
  </div>
</template>


<script>
import LoginJwt from './LoginJWT.vue'

export default {
  components: {
    LoginJwt,
  },
  data() {
    return {
      homeData: null,
    }
  },
  methods: {

    checkOrganisation() {
      this.$store.dispatch('auth/fetchOrgDetails')
        .then((data) => {
          this.balancesError = false
          this.fetchingBalances = false
          if (data.message_type == 1) {
            this.getHomeData()
            this.getOrgName()
          } else {
            this.$router.push({ path: '/pages/error-404' })
          }
        })
        .catch((error) => {
          this.$router.push({ path: '/pages/error-404' })
        })
    },
    getHomeData() {
      try {
        this.homeData = JSON.parse(localStorage.getItem('home'))
      } catch (e) {
        return null
      }
    },
    getOrgName() {
      try {
        return JSON.parse(localStorage.getItem('home')).organisation_name
      } catch (e) {
        return ''
      }
    },
  },
  mounted() {

    this.$store.commit('UPDATE_MAIN_LAYOUT_TYPE', 'horizontal')
    this.$store.dispatch('updateTheme', 'light')
    this.$vs.theme({ primary: '#00a1f1' })
    this.checkOrganisation()
  },
}
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
