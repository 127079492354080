<template>
  <div>
    <transition name="fade">
      <div v-if="!otpSent">
        <vs-input
          v-validate="'required|min:3'"
          validate-on="change"
          name="username"
          icon-no-border
          icon="icon icon-user"
          icon-pack="feather"
          :label="$t('Username')"
          v-model="username"
          class="w-full"/>
        <span class="text-danger text-sm">{{ errors.first('username') }}</span>

        <vs-input
          validate-on="change"
          v-validate="'required|min:6|max:32'"
          type="password"
          name="password"
          icon-no-border
          icon="icon icon-lock"
          icon-pack="feather"
          :label="$t('Password')"
          v-model="password"
          class="w-full mt-6" />
        <span class="text-danger text-sm">{{ errors.first('password') }}</span>

        <div class="flex flex-wrap justify-between my-5">
        </div>
        <div class="flex flex-wrap justify-between mb-3">
          <div></div>
          <vs-button :disabled="!validateForm" @click="loginJWT">{{ $t('Login') }}</vs-button>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="otpSent">
        <vs-input
          v-validate="'required|min:5|max:5'"
          validate-on="change"
          name="otp"
          icon-no-border
          icon="icon icon-shield"
          icon-pack="feather"
          :label-placeholder="$t('OTP')"
          v-model="otp"
          class="w-full"/>
        <span class="text-danger text-sm">{{ errors.first('otp') }}</span>
        <div class="py-2"></div>

        <div class="flex flex-wrap justify-between mb-3">
          <div></div>
          <p>{{ $t('DidNotGetOTP')}}<a class="cursor-pointer font-bold text-primary" @click="callAdminDialog">{{ $t('ClickForAdminApproval') }}</a></p>
          <!--          <vs-button @click="callAdminDialog">{{ $t('CallAdmin') }}</vs-button>-->
        </div>
        <div class="flex flex-wrap justify-between my-5">
        </div>

        <div class="flex flex-wrap justify-between mb-3">
          <vs-button  type="border" @click="cancelLogin">{{ $t('Cancel') }}</vs-button>
          <vs-button :disabled="!otpValidate" @click="verifyOTP">{{ $t('Verify') }}</vs-button>
        </div>


      </div>
    </transition>


    <vx-modal @close="callAdminDialog(false)" v-if="showAdminDialog && loginData != null" component-class="modal-container-fit" >
      <template slot="body">
        <div>
          <!--        reference: "455e"-->
          <!--        reference_id: "95e0b"-->
          <div class=" mb-4" style="font-size: 24px;font-family: Montserrat, Helvetica, Arial, sans-serif;font-weight: 500;line-height: 1.2;color: #2c2c2c;">

            <h1 class=" " style="text-align: center;">{{ $t('ReferenceCode') }}</h1>
          </div>
          <div class="py-4" style="text-align: center;">
            <h4><pre class="ref">{{loginData.reference_id}}</pre></h4>
          </div>

          <div class="flex flex-wrap justify-center mb-3 py-4">
            <vs-button class="mx-3" type="border" @click="callAdminDialog(false)">{{ $t('Cancel') }}</vs-button>
            <vs-button class="mx-3"  @click="checkLoginWithRef">{{ $t('CheckApprovalStatus') }}</vs-button>
          </div>
        </div>
      </template>
    </vx-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import VxModal from '@/layouts/components/custom/VxModal'
import { VueReCaptcha } from 'vue-recaptcha-v3'

export default {
  components: { VxModal },
  data() {
    return {
      username: '',
      otp: '',
      otpSent: false,
      password: '',
      showAdminDialog: false,
      loginData: null,
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.username !== '' && this.password !== ''
    },
    otpValidate() {
      return !this.errors.any() && this.otp !== ''
    },
  },
  methods: {
    loadRecaptcha() {
      try {
        Vue.use(VueReCaptcha, { siteKey: JSON.parse(localStorage.getItem('home')).recaptcha })
      } catch (e) {
        // console.error(e)
        setTimeout(this.loadRecaptcha, 1500)
      }
    },
    callAdminDialog(value) {
      if (value != null) {
        this.showAdminDialog = value
      } else {
        this.showAdminDialog = true
      }
    },
    cancelLogin() {
      this.username = ''
      this.otp = ''
      this.password = ''
      this.otpSent = false
    },

    async checkLoginWithRef() {
      if (!this.checkLogin()) return
      this.$vs.loading()

      const payload = {
        userDetails: {
          reference_id: this.loginData.reference_id,
          reference: this.loginData.reference,
        },
      }

      this.$store.dispatch('auth/verifyOTPWithRef', payload)
        .then((data) => {
          this.$vs.loading.close()
          if (data.status === 200) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Success',
              text: 'OTP Verified',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
            this.otpSent = true
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          position: 'top-center',
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        })
        this.$router.push('/dashboard')

        return false
      }
      return true
    },
    verifyOTP() {
      if (!this.checkLogin()) return
      this.$vs.loading()
      const payload = {
        userDetails: {
          username: this.username,
          otp: this.otp,
        },
      }
      this.$store.dispatch('auth/verifyOTP', payload)
        .then((data) => {
          this.$vs.loading.close()
          if (data.status === 200) {
            this.$vs.notify({
              position: 'top-center',
              title: 'Success',
              text: 'OTP Verified',
              iconPack: 'feather',
              icon: 'icon-check-circle',
              color: 'success',
            })
            this.otpSent = true
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    async loginJWT() {
      if (!this.checkLogin()) return

      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      this.$recaptchaInstance.showBadge()
      // Loading
      this.$vs.loading()


      const recaptcha = this.$recaptchaInstance
      // Hide reCAPTCHA badge:
      recaptcha.hideBadge()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      const payload = {
        userDetails: {
          username: this.username,
          password: this.password,
          token,
        },
      }

      this.$store.dispatch('auth/loginJWT', payload)
        .then((data) => {
          this.$vs.loading.close()

          if (data.status === 200) {
            this.loginData = data.data
            if (!data.data.token) {
              this.$vs.notify({
                position: 'top-center',
                title: 'Success',
                text: 'OTP Sent',
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'success',
              })
            }
            this.otpSent = true
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message ? data.message : data.detail,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message ? error.response.data.message : error.response.data.detail,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.loadRecaptcha();
  }
}

</script>

<style>
.ref {
  background-color: #44D7B6;
  color: white;
  display: inline-block;
  font-size: 30px;
}
</style>

